<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 py-5 d-flex justify-content-between">
      <div class="font-weight-bolder text-primary text-uppercase font-size-h2" v-if="invoices_count == 0">
        Invoices
      </div>
      <div class="font-weight-bolder text-primary text-uppercase font-size-h2" v-else>
         Invoices ({{ invoices_count }})
      </div>
      <div class="d-flex">
         <b-form-select v-model="currentOffice" :options="offices" size="md" class="mr-2" style="width: 350px!important;"></b-form-select>
         <input v-model="startDate" class="form-control mr-2" type="date"/>
         <input v-model="endDate" class="form-control mr-2" type="date"/>
        <b-form-input v-model="search_text" placeholder="Search..." ></b-form-input>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <ag-grid-vue
                rowHeight="65"
                :masterDetail="true"
                :detailCellRenderer="'invoiceDetailRenderer'"
                class="ag-theme-material ag-grid-vue"
                :columnDefs="columnDefs"
                :pagination="false"
                @grid-ready="onGridReady"
                :defaultColDef="defaultColDef"
                :frameworkComponents="frameworkComponents"
                :rowData="items"
                >

            </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import { GET_INVOICES } from "@/core/services/store/profile.module";
import {GET_OFFICES} from "@/core/services/store/profile.module";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridVue } from 'ag-grid-vue';

import ActionsRenderer from './Render/ActionsRenderer.js';
import NameRenderer from './Render/NameRenderer.js';

import InvoiceDetailRenderer from './Render/InvoiceDetailRenderer.js';

import { LicenseManager } from "ag-grid-enterprise";
import 'ag-grid-enterprise';

LicenseManager.setLicenseKey("Servoy_B.V_Servoy_7Devs_200Deployment_11_October_2020__MTYwMjM3MDgwMDAwMA==26d908e26f73b44683ba7a5dfdd00755");

export default {
  name: "transactions",
  components: {
     AgGridVue,
  },
  data() {
    let today = new Date();
    let yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let lastDate =  new Date(yyyy, mm, 0);
    let dd = lastDate.getDate()
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    let date = yyyy + '-' + mm + '-' + dd;
    let start_date = yyyy + '-' + mm + '-' + '01';
    return {
      search_text: '',
      invoices_count: 0,
      deleteConfirmDialog: true,
      selectedPk: null,
      startDate: start_date,
      endDate: date,
      formData:{

      },
      txtFile: null,
      items: [],
      columnDefs:  [
       {
         headerName:'Invoice #',
         cellRenderer: 'agGroupCellRenderer',
         field: 'number',
         minWidth:200,
          cellStyle: {
           textAlign: 'center',
           fontWeight:'500'
         },
       },
       {
         headerName: 'Company Name/Member Name',
         field: 'company_member',
         minWidth:400,
         cellStyle: {
           textAlign: 'center',
         },
         valueFormatter: companyMemberFormatter
       },
       {
         headerName: 'Location',
         field: 'location',
         cellStyle: {
           textAlign: 'center',
         }
       },
        {
         headerName: 'Issued Date',
         field: 'issued_date',
          cellStyle: {
           textAlign: 'center',
          }
       },
        {
         headerName: 'Status',
         field: 'status',
         cellStyle: {
           textAlign: 'center',
           textTransform: 'capitalize'
         },
       },
        {
         headerName: 'Amount',
         field: 'amount',
          maxWidth: 160,
          valueFormatter: currencyFormatter,
          cellStyle: cellStyle
       },
        { headerName: 'Location', field: 'location', hide: true },
     ],
      gridApi: null,
      columnApi: null,
      currentOffice:null,
      defaultColDef: {
        sortable: true,
        suppressMenu: true,
        flex: 1,
      },
      frameworkComponents : null,
      edit_or_create: true,
      offices : []
    };
  },
  computed: {
    ...mapState({
      errors: state => state.symbol.errors
    }),
    ...mapGetters(["layoutConfig"]),
    checkForm(){
      return !(this.formData.min_dte !== null  && this.formData.max_dte !== null && this.formData.cent !== null)
    }
  },
  methods: {
    getOffices(){
      this.$store
          .dispatch(GET_OFFICES)
          .then((data) => {
            this.offices = data;
            if(this.offices.length > 0)
              if(this.currentOffice == null)
                this.currentOffice = this.offices[0].value;
          });
    },
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.loadInvoices();
     },
    loadInvoices(){
      if(this.currentOffice !== null){
        this.gridApi.showLoadingOverlay();
         this.$store
          .dispatch(GET_INVOICES, {
            office: this.currentOffice,
            start: this.startDate,
            end: this.endDate
          })
          .then((data) => {
            this.items = data;
            this.invoices_count = this.items.length;
            this.gridApi.hideOverlay();
          })
      }
      }

  },
  beforeMount() {
     this.frameworkComponents = {
       'actionsRenderer': ActionsRenderer,
       'invoiceDetailRenderer' : InvoiceDetailRenderer,
       'nameRenderer' : NameRenderer
     };
  },
  watch: {
    search_text(new_value, old_value) {
       this.gridApi.setQuickFilter(new_value);
    },
    startDate(new_value, old_value){
      this.loadInvoices()
    },
    endDate(new_value, old_value){
      this.loadInvoices()
    },
    currentOffice(new_value){
      localStorage.setItem('currentOffice', this.currentOffice)
      this.loadInvoices()
    },

  },
  created() {
    if(localStorage.getItem('currentOffice'))
      this.currentOffice = localStorage.getItem('currentOffice');
   this.getOffices();
  },
};

window.currencyFormatter = function currencyFormatter(params) {
  if(params.data.credit) return '- $' + params.value;
  return '$' + params.value;
};

window.companyMemberFormatter = function companyMemberFormatter(params) {
  if(params.data.company && params.data.member) return params.data.company + '/' + params.data.member;
  if(params.data.company) return params.data.company;
  if(params.data.member) return params.data.member;
  return '-';
};

window.phoneFormatter = function phoneFormatter(params) {
  if(!params.value) return '-';
  var cleaned = ('' + params.value).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return [ '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return params.value
};

window.cellStyle = function cellStyle(params) {
  if(params.data.credit)  return {
    color: 'red',
    textAlign: 'center',

  };
  return {
    textAlign: 'center',

  };

};


</script>
<style>
.ag-header-cell-label {
   justify-content: center;
}
 .btn-create {
    margin-left: 10px;
    border-radius: 33px;
    height: 28px;
    padding: 0rem 1rem!important;
 }
 .btn-create i{
    font-size: 1rem!important;
    padding-right: 0.1rem!important;
 }
 .modal-input{
   height: 45px!important;
 }
  .ag-grid-vue{
       width: 100%!important;
       height: calc(100vh - 230px)!important;
    }

    /* This CSS is to not apply the border for the column having 'no-border' class */
    .no-border.ag-cell:focus{
        border:none !important;
        outline: none;
    }
    .ag-cell-focus,.ag-cell-no-focus{
        border:none !important;
    }

    .ag-row-selected {
        background-color: transparent!important;
    }
    .ag-theme-material .ag-details-row {
       padding: 10px 40px 10px 40px!important;
    }

    div.ag-theme-material div.ag-row {
      font-size: 15px !important;
      padding-top: 10px!important;
    }
    .container {
      max-width: 98vw!important;
    }
</style>
