<template>
    <div v-if="params.data"  class="invoice-detail-div pt-12">
      <div class="invoice-detail">
        <v-row v-for="item in params.data.lines" class="py-3">
        <v-col cols="9" offset="1" class="py-0">
         {{  item.name }}
        </v-col>
        <v-col cols="2"  class="py-0">
         $ {{  item.total }}
        </v-col>
      </v-row>
        <v-row  class="border-top-invoice py-4">
        <v-col cols="9" offset="1" class="py-0">
         <b>Total</b>
        </v-col>
        <v-col cols="2"  class="py-0">
         <b>$ {{  params.data.amount }}</b>
        </v-col>
      </v-row>
      </div>
    </div>
</template>

<script>

    export default {
        name: "ActionsRenderer",
        components: {
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        methods: {

        },
       computed: {

       },
    }
</script>
<style>
.invoice-detail-div{
  display: flex;
  justify-content: center;
}
.invoice-detail{
  min-width: 800px!important;
  text-align: left;
}
.border-top-invoice{
  border-top: 1px solid black;
}
.row{
  background-color: #e7e6e6!important;
}
.invoice-detail div.row:first-child {
  padding-top: 15px!important;
}
.invoice-detail div.row:last-child {
  padding-bottom: 15px!important;
}
</style>

